import React from 'react';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { BlogSnippet } from './BlogSnippet';

export const SiteContentWrapper: React.FC<{
    content: string;
    options: MarkdownToJSX.Options;
    className?: string;
}> = (props) => {

    props.options.overrides = {
        ...props.options.overrides,
        "blogsnippet": {
            component: BlogSnippet
        }
    };
    
    return props.content && (
        <div className={props.className}>
            <Markdown options={props.options}>{props.content}</Markdown>
        </div>
    )
};