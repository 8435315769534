import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { blogTitleToUrlSlug } from '../helpers';

export const BlogSnippet = ({ children, ...props }) => {
    const isDev = process.env.NODE_ENV === 'test';
    const imgName = 'lead.jpg';
    const imgSrc = isDev
        ? `https://caffeinedreams.co.uk/static/images/blog/${blogTitleToUrlSlug(props.title)}/${imgName}`
        : `static/images/blog/${blogTitleToUrlSlug(props.title)}/${imgName}`;

    return (
        <>
            <h4>{children}</h4>
            <LazyLoadImage
                wrapperClassName="blog-snippet-img"
                effect="blur"
                alt={'Blog Post: ' + props.title}
                src={imgSrc}
            />
        </>
    );
};