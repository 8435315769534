import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlogPostModel } from '../Models/BlogModels';
import { GetBlogSnippetAsync } from '../services/blog.service';
import { SiteContentWrapper } from './SiteContentWrapper';

export const BlogPost: React.FC = () => {
    const params = useParams();
    const [post, setPost] = useState<BlogPostModel>();

    useEffect(() => {
        loadBlogPost();
    }, []);

    const loadBlogPost = (): void => {
        GetBlogSnippetAsync(params.slug)
            .then((v) => setPost(v));
    };
    
    return (
        post ? (
            <>
                <h1>{post.title}</h1>
                {post.tags.map((val, i) => (
                    <h3 
                        key={`_${val}_${i}`}
                        className="blogTags"
                    >
                        {val}
                    </h3>
                ))}
                <article>
                    <SiteContentWrapper
                        content={post.body}
                        options={{}}
                    />
                </article>
            </>
         ) : (
            <>
                <h3>Loading...</h3>
            </>
         )
    )
}
