import React, { useEffect } from "react";
import { render } from "react-dom";
import { HelloWorld } from "./components/HelloWorld";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";
import { routes } from "./constants/site.constants";
import { applyLogo } from "./logo";
import { CopyrightNotice } from "./components/CopyrightNotice";
import { BlogPost } from "./components/BlogPost";

const Contact: React.FC = () => {
    return (
        <h1>Contact</h1>
    )
};

const App: React.FC = () => {
    useEffect(() => {
        applyLogo();
    });
    
    return (
        <Router>
            <div className="container">
                <header id="logo" className="-docked">
                    <canvas width='210' height='210'></canvas>
                    <div>
                        <h1>Caffeine Dreams</h1>
                        <h2>Development Labs</h2>
                    </div>
                    <nav>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </header>
                <main className="page">
                    <Switch>
                        <Route
                            path={routes.blog + "/:slug"}
                            component={BlogPost}
                        />
                        <Route path={routes.contact}>
                            <Contact />
                        </Route>
                        <Route path={routes.home}>
                            <HelloWorld />
                        </Route>
                    </Switch>
                </main>
                <footer>
                    <CopyrightNotice />
                </footer>
            </div>
        </Router>
    )
}

render(<App />, document.getElementById("root"));