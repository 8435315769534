import { URLSearchParams } from "url";
import { BlogPostModel, BlogSearchQuery } from "../Models/BlogModels";

export const GetBlogSnippetAsync = async (title: string): Promise<BlogPostModel> => {
    return fetch(`/api/blog/article/${title}`)
        .then((res) => {
            return res.json()
        });
};

export const QueryBlogPostsAsync = async (query: BlogSearchQuery): Promise<BlogPostModel[]> => {
    const paramStr = new URLSearchParams(query as any);
    return fetch(`/api/blog/articles${paramStr}`)
        .then((res) => {
            return res.json()
        });
}