export const routes = {
    blog: '/blog',
    home: '/',
    contact: '/contact'
};

export const links = [{
    href: routes.blog,
    name: 'Blog',
    title: 'My blog. I publish articles if I find something interesting or just when I feel like it',
    external: false,
    icon: 'cdicons blog'
  },{
    href: 'https://www.linkedin.com/in/caffeinedreams?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Beg9pUA78TlK6PgjxiZy65A%3D%3D',
    name: 'LinkedIn',
    title: 'My LinkedIn profile',
    external: true,
    icon: 'icono-linkedIn'
  },{
    href: 'https://codepen.io/CaffeineDreams',
    name: 'Codepen.io',
    title: 'My Codepen profile',
    external: true,
    icon: 'icono-codepen'
  },{
    href: 'https://drive.google.com/file/d/12E1N8Nk6yeJOdq5YAdyLJAVXfXafC3EL/view?usp=sharing',
    name: 'CV',
    title: 'My CV',
    external: true,
    icon: 'icono-file'
  },{
    href: routes.contact,
    name: 'Contact',
    title: 'Contact me',
    external: false,
    icon: 'icono-mail'
  },{
      href: routes.home,
      name: 'Home',
      title: 'Home',
      external: false,
      icon: 'icono-home'
  }];