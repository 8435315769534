export const applyLogo = () => {
    const canvas = document
        .getElementById('logo')
        .getElementsByTagName('canvas')[0];

    const ctx = canvas.getContext('2d');
// #layer1
    

// #g977
    
// #g981
    ctx.save();
    ctx.transform(-0.965926, 0.258819, -0.258819, -0.965926, 130.626000, 169.737000);
    ctx.restore();
    
// #path991
    ctx.save();
    ctx.beginPath();
    ctx.transform(0.333037, 0.000000, 0.000000, 0.333037, 62.278900, 60.422400);
    ctx.globalAlpha = 1.0;
    ctx.fillStyle = 'rgb(32,32,32)';
    ctx.lineWidth = 10.583333;
    ctx.lineCap = 'butt';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 4;
    ctx.moveTo(382.295310, 27.880358);
    ctx.lineTo(317.529350, 190.762520);
    ctx.lineTo(354.178380, 202.807500);
    ctx.lineTo(415.848300, 42.151237);
    ctx.moveTo(161.747670, 20.987070);
    ctx.lineTo(113.450790, 31.382583);
    ctx.lineTo(158.085560, 203.979170);
    ctx.lineTo(206.382440, 187.565190);
    ctx.moveTo(62.659153, 327.287620);
    ctx.lineTo(193.390690, 197.037070);
    ctx.lineTo(145.760900, 19.280301);
    ctx.lineTo(-32.581197, -28.154224);
    ctx.lineTo(-163.310840, 102.095820);
    ctx.lineTo(-115.681060, 279.852590);
    ctx.lineTo(-110.090200, 281.339030);
    ctx.moveTo(56.688123, 305.003410);
    ctx.lineTo(-99.357662, 263.498260);
    ctx.lineTo(-140.997200, 108.097400);
    ctx.lineTo(-26.610159, -5.870004);
    ctx.lineTo(129.437510, 35.634636);
    ctx.lineTo(171.077050, 191.035500);
    ctx.moveTo(360.722120, 213.053550);
    ctx.lineTo(427.600270, 38.831165);
    ctx.lineTo(282.570900, -78.610774);
    ctx.lineTo(276.477020, -74.654614);
    ctx.lineTo(126.059720, 23.028551);
    ctx.lineTo(174.359800, 203.286910);
    ctx.moveTo(347.256550, 192.320520);
    ctx.lineTo(189.917360, 184.074420);
    ctx.lineTo(149.139200, 31.888279);
    ctx.lineTo(281.275390, -53.924534);
    ctx.lineTo(403.720020, 45.229860);
    ctx.fill();
    ctx.restore();
};